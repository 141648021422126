import { Link } from 'components/ui/links/link'
import { TRENDING, UNDER_MAINTENANCE } from 'utils/constants'

export const NAVIGATION_BREAKPOINT = '890px'

const COLUMN_WIDTH = '120px'

export const MOMENTUM_COLUMN = 'Momentum'
export const GENERAL_COLUMN = 'General'
export const RISK_COLUMN = 'Risk'
export const REWARD_COLUMN = 'Reward'
export const ROI_365D_COLUMN = 'ROI 365d'

export const TOOLTIPS = {
    price: {
        title: 'Price',
        text: 'The current market value of 1 unit of an asset in USD. It is the average of the price buyers are willing to pay, and the price sellers are willing to sell at current point in time. The price of an asset can fluctuate based on supply and demand and market conditions.',
    },
    priceChangePercentage: {
        title: 'Price Change 24h',
        text: 'The percentage change in the price of the asset over the last 24 hours. A positive percentage indicates an increase in price, while a negative percentage indicates a decrease in price. This metric can provide insights into the short-term trends and fluctuations in price for the asset, as well as the overall level of market interest and liquidity.',
    },
    marketcap: {
        title: 'Market Cap',
        text: 'The theoretical value of this asset calculated by multiplying the current price by the current circulating supply. A key indicator of an assets size and significance in the market. Keep in mind, a higher market cap often implies more stability, but not always!',
    },
    dailyVolume: {
        title: 'Daily Volume',
        text: 'The aggregate trading volume of all trading pairs for the asset over the past 24 hours. This metric provides insights into the level of market activity and liquidity for a given asset, and can be used to identify trends in trading activity and investor sentiment.',
    },
    rewardRate: {
        title: 'Reward Rate',
        text: 'The current annualized average reward rate across the network. This is the rate at which stakers can earn rewards for participating in network consensus and/or governance.',
    },
    pegStability: {
        title: 'Peg Stability',
        text: "The stability of a stablecoin's peg to its target value. A peg stability of 100% indicates that the stablecoin's value is perfectly pegged to its target value, while a lower percentage indicates a deviation from the target value. The color-coding for peg stability is as follows: green for 100% to 99%, yellow for 99% to 98%, red for 98% to 95%, and purple for below 95%.",
    },
    stakingMarketcap: {
        title: 'Staking MarketCap',
        text: 'The total value of staked tokens across the network. This is calculated by multiplying the staked tokens with the current price. It’s important to note that, under certain circumstances, tokens that have not yet vested (locked tokens) may also be staked. This means that early investors are able to receive staking rewards on tokens that are unvested, potentially leading to a staking market capitalization that surpasses the actual market capitalization. This metric can give an indication of the overall market size and longterm confidence in the network.',
    },
    providers: {
        title: 'SR Supported Providers',
        text: 'A list of all the providers currently tracked by StakingRewards. These providers may include custodians, DeFi protocols and other services that offer lending services for various assets.',
    },
    roi365dStaking: {
        title: 'ROI 365d (Staking)',
        text: 'The total returns earned from holding an asset over the past 365 days, based solely on staking rewards earned through participation in the network. This metric does not take into account any price appreciation or other market factors. It can provide a useful benchmark for assessing the long-term profitability of staking the asset. A higher ROI 365d (Staking) value suggests a more profitable staking opportunity, while a lower value may indicate lower returns or potential risks or uncertainties for staking the asset.',
    },
    roi365dPrice: {
        title: 'ROI 365d (Price)',
        text: 'The total returns earned from holding an asset over the past 365 days, based solely on price appreciation. This metric does not take into account any staking rewards earned through participation in the network. It can provide a useful benchmark for assessing the long-term performance and profitability of the asset. A higher ROI 365d (Price) value suggests a more profitable investment, while a lower value may indicate lower returns or potential risks or uncertainties for the asset.',
    },
    roi365dTotal: {
        title: 'ROI 365d (Total)',
        text: 'The total returns earned from holding and staking the asset over the past 365 days. The calculation is based on the the price appreciation of the asset over this time period and staking yield earned through participation in the network. It can provide a useful benchmark for assessing the long-term performance and profitability of the asset. A higher ROI 365d (Total) value suggests a more profitable investment, while a lower value may indicate lower returns or potential risks or uncertainties for the asset.',
    },
    tradingVolume24h: {
        title: 'Trading Volume Trend 24h',
        text: 'The change in the total trading volume of the asset over the last 24 hours, compared to the average trading volume over the last 30 days. This metric can provide insights into the short-term trends and fluctuations in trading activity for the asset, as well as the overall level of market interest and liquidity. A positive trend in trading volume may indicate growing demand and interest in the asset, while a negative trend may suggest declining interest or potential risks or uncertainties for the asset.',
    },
    stakedTokensTrend24h: {
        title: 'Staked Tokens Trend 24h',
        text: 'The trend in staked tokens of the network over the last 24 hours, compared to the average staked tokens over the last 30 days. This metric can provide insights into the short-term trends and fluctuations in staking activity on the network, as well as the overall level of participation among token holders. A positive trend may indicate growing interest and confidence in the network, while a negative trend may suggest declining interest or potential risks or uncertainties for the network.',
    },
    realRewardRate: {
        title: 'Real Reward Rate',
        text: 'The nominal reward rate of the network adjusted for inflation. This metric can provide insights into the actual, inflation-adjusted return that stakers or delegators can expect to receive for participating in the network, and can help investors understand the potential long-term value of their token holdings. Higher real reward rates generally indicate greater potential returns for stakers or delegators, while lower real reward rates may indicate lower expected returns or potentially negative real yields in some cases',
    },
    stakingRatio: {
        title: 'Staking Ratio',
        text: 'The percentage of eligible tokens that are currently being staked or delegated to the network. This metric can provide insights into the level of network participation among token holders, as well as the overall health and security of the network. A higher staking ratio generally indicates a more committed community of token holders, as well as a more secure and decentralized network, while a lower staking ratio may indicate lower levels of engagement, and potentially greater network centralization or security risks.',
    },
    stakingWallets: {
        title: 'Staking Wallets',
        text: 'The total number of unique wallet addresses that are actively staking or delegating tokens to the network. This metric can provide insights into the level of network participation and decentralization, as well as the distribution of staking rewards among network participants. Higher numbers of staking wallets may indicate a greater degree of decentralization and network security, as well as a more engaged and active community of token holders.',
    },
    validators: {
        title: 'Validators',
        text: "Validators are nodes managed by this operator, each requiring a 32 ETH deposit. They store data, process transactions, and create new blocks, contributing to Ethereum's consensus via Proof of Stake. This metric indicates the operator's engagement in upholding network security and protocol reliability. More validators signify a stronger role in network validation, potentially enhancing Ethereum's performance.",
    },
    inflationRate: {
        title: 'Inflation Rate',
        text: 'This metric calculates the annualized expansion rate of an asset’s circulating supply, focusing solely on the current token reward payout and its accrual interval. It offers a clear view of the asset’s short-term supply changes, excluding the total supply from its calculation. High expansion rates indicate a rapid increase in circulating tokens, potentially leading to the dilution of the value of existing holders’ tokens. Conversely, low expansion rates suggest a slower growth in supply, which may enhance token scarcity and support price appreciation. This approach provides a more immediate understanding of the asset’s supply dynamics and its potential impact on value.',
    },
    stakedTokens: {
        title: 'Staked Tokens',
        text: 'The total number of tokens that are currently being staked across the network. The amount of staked tokens can give an indication of the level of participation and interest in staking among network participants.',
    },
    rewardsPerYear: {
        title: 'Rewards Per Year',
        text: 'The expected USD value of rewards paid out by this network taking into account current values for reward rate, inflation, staking participation and asset price and other network parameters that influence the rewards. Be aware that this is a snapshot of how the network is now, and not modelled on how the network could evolve over the next 12 months.',
    },
    netStakingFlow: {
        title: 'Net Staking Flow 24h',
        text: 'The number of tokens staked in the last 24 hours minus the number of tokens unstaked in the last 24 hours, multiplied by the current price of the asset.  A positive net staking flow indicates an increase in staked tokens, while a negative net staking flow indicates a decrease. As this metric is displayed in USD, it can easily be compared between assets.',
    },
    underMaintenance: (assetName = 'Asset') => ({
        title: UNDER_MAINTENANCE,
        text: `${assetName} is currently undergoing some API-connection maintenance on Staking Rewards. We are working to get this solved as we speak. Until then, data presented on this page may or may not be accurate and up to date.`,
    }),
    trending: percentage => ({
        title: TRENDING,
        text: `This asset has seen an increase ${percentage} percentage in page views over the past 12 hours compared to the preceding 30 days. This asset is one of the top 10 highest-trending assets in the past 12 hours on stakingrewards.com.`,
    }),
    pageViews12h: {
        title: 'Page Views Trend 12h',
        text: 'The Page Views Trend 12h compares the page views in the last 12 hours against the average page views over the previous 30 days. This comparison allows for the identification of recent trends in popularity. Please note that a minimum threshold is applied to the 30-day average to avoid skewed results from assets with low average page views.',
    },
    feeRevenue: {
        title: 'Annualized Fee Revenue',
        text: 'The Annualized fee revenue represents the anticipated revenue, expressed in USD, that this protocol is expected to generate annually from transaction fees alone. It does not factor in potential rewards resulting from inflation.',
    },
    circulatingPercentage: {
        title: 'Circulating Percentage',
        text: "The Circulating Percentage is determined by dividing the circulating supply by the total supply. If the value is low, it indicates that a significant portion of the supply is currently inaccessible or 'locked', implying a potential influx into the market in the future.",
    },
    rrRatio: {
        title: 'R/R Ratio',
        text: "The Risk/Reward ratio represents the ratio of the protocol's projected annual fee revenue to its expected annual reward payouts.",
    },
    stakingRiskRating: {
        title: 'Staking Risk Rating',
        text: 'The Risk Rating is Staking Experts’ opinion on how risky the native staking mechanism of this asset is. Considered core factors are Bonding and unbonding periods (or deposit/withdrawal queues), slashing risk, how new the mechanism is, and whether it is based on an already existing mechanism (such as Tendermint). Please note that this metric is currently in beta, and continuously being worked and improved upon to better the scoring framework.',
    },
    rewardStability: {
        title: 'Reward Stability',
        text: 'The Reward Stability is computed by comparing the realized reward rate over the last 365 days to the expected reward rate 365 days ago. This metric tries to give you an indication into how reliable the expected reward rate for this asset is. Please note that this metric is currently in beta, and continuously being worked and improved upon to better the scoring framework.',
    },
    ethEconomicSecurity: {
        title: 'ETH Economic Security',
        text: 'ETH Economic Security refers to the collective and individual economic safeguards provided to AVSs on the Ethereum network by EigenLayer. This includes a shared security base that ensures a robust and scalable defense against potential threats to the network’s integrity.',
    },
    restakedEth: {
        title: 'Restaked ETH',
        text: 'Restaked ETH represents the total amount of Ethereum (ETH + LSTs) that has been restaked with this AVS, indicating the level of trust and participation in the AVS’s economic security.',
    },
    operators: {
        title: 'Operators',
        text: 'Operators are the entities responsible for running the AVS. This metric shows the number of AVS operators currently active, which can be an indicator of the network’s decentralization and reliability.',
    },
    restakers: {
        title: 'Restakers',
        text: 'Restakers are the unique wallets that have chosen to restake their ETH with this AVS. This number reflects the community’s engagement and the AVS’s popularity among stakeholders.',
    },
    raver: {
        isRaver: true,
    },
}

export const RaverTooltip = () => {
    const RAVER_LINK =
        'https://docs.rated.network/methodologies/ethereum/rated-effectiveness-rating'
    return (
        <div className='flex flex-col'>
            <span>
                The{' '}
                <b className='font-bold'>Rated Effectiveness Rating (RAVER)</b>{' '}
                is a composite metric of Node Operator performance produced by
                Rated, with community input.
            </span>
            <Link
                href={RAVER_LINK}
                className='text-primary mt-2'
                blank
                external
            >
                Learn more about the methodology
            </Link>
        </div>
    )
}

export const MAIN_ASSET_METRIC_GROUPS = [
    {
        key: 'price',
        label: 'Price',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.price,
        withPercentage: true,
        width: '120px',
        showPlus: false,
        column: GENERAL_COLUMN,
        withColor: true,
    },
    {
        key: 'staking_marketcap',
        label: 'Staking Market Cap',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.stakingMarketcap,
        withPercentage: true,
        width: '130px',
        showPlus: false,
        column: RISK_COLUMN,
        withColor: true,
    },
    {
        key: 'staking_ratio',
        label: 'Staking Ratio',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.stakingRatio,
        withPercentage: true,
        width: '120px',
        showPlus: false,
        column: RISK_COLUMN,
        withColor: true,
    },
    {
        key: 'reward_rate',
        label: 'Reward Rate',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.rewardRate,
        withPercentage: true,
        width: '100px',
        showPlus: false,
        column: REWARD_COLUMN,
        withColor: true,
    },
]

export const STABLECOINS_ASSET_METRIC_GROUPS = [
    {
        key: 'price',
        label: 'Price',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.price,
        withPercentage: true,
        width: COLUMN_WIDTH,
        showPlus: false,
        withColor: true,
    },
    {
        key: 'marketcap',
        label: 'Market Cap',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.marketcap,
        withPercentage: true,
        width: COLUMN_WIDTH,
        showPlus: false,
        withColor: true,
    },
    {
        key: 'daily_trading_volume',
        label: 'Daily Volume',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.dailyVolume,
        withPercentage: true,
        width: COLUMN_WIDTH,
        showPlus: false,
        withColor: true,
    },
    {
        key: 'reward_rate',
        label: 'Reward Rate',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.rewardRate,
        withPercentage: true,
        width: '100px',
        showPlus: false,
        withColor: true,
    },
    {
        key: 'staking_marketcap',
        label: 'Staking Market Cap',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.stakingMarketcap,
        withPercentage: true,
        width: '110px',
        showPlus: false,
        withColor: true,
    },
    {
        key: 'peg_stability',
        label: 'Peg Stability',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.pegStability,
        withPercentage: false,
        width: '100px',
        showPlus: false,
        withColor: true,
    },
]

export const LST_MAIN_ASSET_METRIC_GROUPS = [
    {
        key: 'reward_rate',
        label: 'Reward Rate',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.rewardRate,
        withPercentage: true,
        width: '100px',
        showPlus: false,
        withColor: true,
    },
    {
        key: 'marketcap',
        label: 'Market Cap',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.marketcap,
        withPercentage: true,
        width: '120px',
        showPlus: false,
        withColor: true,
    },
    {
        key: 'peg_deviation',
        label: 'Peg Accuracy',
        postfix: '%',
        tooltipTextObj: null,
        withPercentage: false,
        width: '100px',
        showPlus: false,
        withColor: true,
    },
]

const MORE_ASSET_METRIC_GROUPS = [
    {
        key: 'marketcap',
        label: 'Market Cap',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.marketcap,
        withPercentage: true,
        column: GENERAL_COLUMN,
        width: '140px',
        showPlus: false,
        withColor: true,
    },
    {
        key: 'daily_trading_volume',
        label: 'Daily Volume',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.dailyVolume,
        withPercentage: true,
        column: GENERAL_COLUMN,
        width: '150px',
        showPlus: false,
        withColor: true,
    },
    {
        key: 'net_staking_flow',
        label: 'Net Staking Flow 24h',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.netStakingFlow,
        withPercentage: false,
        width: '140px',
        showPlus: true,
        column: GENERAL_COLUMN,
        withColor: false,
        timeframeKey: '24h',
    },
    {
        key: 'inflation_rate',
        label: 'Inflation Rate',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.inflationRate,
        withPercentage: true,
        column: RISK_COLUMN,
        width: '130px',
        showPlus: false,
        withColor: true,
    },
    {
        key: 'total_staking_wallets',
        label: 'Staking Wallets',
        tooltipTextObj: TOOLTIPS.stakingWallets,
        withPercentage: true,
        column: RISK_COLUMN,
        width: '130px',
        showPlus: false,
        withColor: true,
    },
    {
        key: 'real_reward_rate',
        label: 'Real Reward Rate',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.realRewardRate,
        withPercentage: true,
        column: REWARD_COLUMN,
        width: '130px',
        showPlus: false,
        withColor: true,
    },
    {
        key: 'staked_tokens',
        label: 'Staked Tokens',
        tooltipTextObj: TOOLTIPS.stakedTokens,
        withPercentage: true,
        column: REWARD_COLUMN,
        width: '130px',
        withColor: true,
    },
    {
        key: 'annualized_rewards_usd',
        label: 'Rewards Per Year',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.rewardsPerYear,
        withPercentage: true,
        width: '130px',
        showPlus: false,
        column: REWARD_COLUMN,
        withColor: true,
    },
    {
        key: 'staked_tokens_trend_24h',
        label: 'Staked Tokens Trend 24h',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.stakedTokensTrend24h,
        withPercentage: false,
        column: MOMENTUM_COLUMN,
        width: '150px',
        showPlus: true,
        withColor: true,
    },
    {
        key: 'trading_volume_trend_24h',
        label: 'Trading Volume Trend 24h',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.tradingVolume24h,
        withPercentage: false,
        column: MOMENTUM_COLUMN,
        width: '150px',
        showPlus: true,
        withColor: true,
    },
    {
        key: 'total_roi_365d',
        label: 'Total',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.roi365dTotal,
        withPercentage: false,
        column: ROI_365D_COLUMN,
        width: '60px',
        showPlus: true,
        withColor: false,
    },
    {
        key: 'staking_roi_365d',
        label: 'Staking',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.roi365dStaking,
        withPercentage: false,
        column: ROI_365D_COLUMN,
        width: '60px',
        showPlus: true,
        withColor: false,
    },
    {
        key: 'price_roi_365d',
        label: 'Price',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.roi365dPrice,
        withPercentage: false,
        column: ROI_365D_COLUMN,
        width: '60px',
        showPlus: true,
        withColor: false,
    },
]

export const AVS_ASSET_METRIC_GROUPS = [
    {
        key: 'restaked_tokens',
        label: 'Restaked ETH',
        postfix: '',
        tooltipTextObj: TOOLTIPS.restakedEth,
        withPercentage: true,
        showPlus: false,
        withColor: true,
    },
    {
        key: 'restaking_marketcap',
        label: 'Economic Security',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.ethEconomicSecurity,
        withPercentage: true,
        showPlus: false,
        withColor: true,
    },
    {
        key: 'operators',
        label: 'Operators',
        tooltipTextObj: TOOLTIPS.operators,
    },
    {
        key: 'restaking_wallets',
        label: 'Restakers',
        tooltipTextObj: TOOLTIPS.restakers,
        withPercentage: true,
        showPlus: false,
        withColor: true,
    },
]

const ETH_MORE_ASSET_METRIC_GROUPS = MORE_ASSET_METRIC_GROUPS.map(group => {
    if (group.key === 'total_staking_wallets') {
        return {
            ...group,
            label: 'Validators',
            tooltipTextObj: TOOLTIPS.validators,
        }
    }

    return group
})

export const getMoreAssetMetricGroups = (slug = '') => {
    return slug === 'ethereum-2-0'
        ? ETH_MORE_ASSET_METRIC_GROUPS
        : MORE_ASSET_METRIC_GROUPS
}

export const ASSETS_SORT_BY_CHOICES = [
    ...MAIN_ASSET_METRIC_GROUPS,
    ...MORE_ASSET_METRIC_GROUPS,
]
    .filter(
        group =>
            !['total_roi_365d', 'price_roi_365d', 'net_staking_flow'].includes(
                group.key
            )
    )
    .map(group => {
        let label = group.label
        if (group.key === 'staking_roi_365d') {
            label = 'Staking ROI 365d'
        }
        return {
            key: group.key,
            name: label,
            withPercentage: group.withPercentage,
        }
    })

export const DEFAULT_ASSETS_SORT_BY = ASSETS_SORT_BY_CHOICES.find(
    choice => choice.key === 'staking_marketcap'
)

export const LST_ASSETS_SORT_BY_CHOICES = LST_MAIN_ASSET_METRIC_GROUPS.map(
    group => {
        const label = group.label
        return {
            key: group.key,
            name: label,
            withPercentage: group.withPercentage,
        }
    }
)

export const AVS_ASSETS_SORT_BY_CHOICES = AVS_ASSET_METRIC_GROUPS.map(group => {
    const label = group.label
    return {
        key: group.key,
        name: label,
        withPercentage: group.withPercentage,
    }
})

export const DEFAULT_AVS_ASSETS_SORT_BY = AVS_ASSETS_SORT_BY_CHOICES.find(
    choice => choice.key === 'restaking_marketcap'
)

export const DEFAULT_LST_ASSETS_SORT_BY = LST_ASSETS_SORT_BY_CHOICES.find(
    choice => choice.key === 'marketcap'
)

export const UNTRACKED_ASSET_METRIC_GROUPS = [
    {
        key: 'price',
        label: 'Price',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.price,
        withPercentage: false,
        showPlus: false,
        withColor: false,
    },
    {
        key: 'price',
        label: 'Price Change 24h',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.priceChangePercentage,
        withPercentage: false,
        showPlus: true,
        withColor: true,
        timeframeKey: '24h',
    },
    {
        key: 'marketcap',
        label: 'Market Cap',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.marketcap,
        withPercentage: false,
        showPlus: false,
        withColor: false,
    },
    {
        key: 'daily_trading_volume',
        label: 'Daily Volume',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.dailyVolume,
        withPercentage: false,
        column: GENERAL_COLUMN,
        showPlus: false,
        withColor: false,
    },
]

export const PRESEARCH_METRIC_GROUPS = [
    ...MAIN_ASSET_METRIC_GROUPS,
    ...getMoreAssetMetricGroups('presearch'),
].filter(metricGroup =>
    ['price', 'marketcap', 'daily_trading_volume'].includes(metricGroup.key)
)

export const STABLECOIN_ASSET_METRIC_GROUPS = [
    {
        key: 'price',
        label: 'Price',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.price,
        withPercentage: false,
        width: COLUMN_WIDTH,
        showPlus: false,
        withColor: false,
    },
    {
        key: 'marketcap',
        label: 'Market Cap',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.marketcap,
        withPercentage: true,
        width: COLUMN_WIDTH,
        showPlus: false,
        withColor: true,
    },
    {
        key: 'daily_trading_volume',
        label: 'Trading Volume 24h',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.dailyVolume,
        withPercentage: true,
        width: COLUMN_WIDTH,
        showPlus: false,
        withColor: true,
    },
    {
        key: 'peg_stability',
        label: 'Peg Accuracy',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.pegStability,
        withPercentage: false,
        width: '100px',
        showPlus: false,
        withColor: true,
    },
    // {
    //     key: 'reward_rate',
    //     label: 'Reward Rate',
    //     postfix: '%',
    //     tooltipTextObj: TOOLTIPS.rewardRate,
    //     withPercentage: false,
    //     width: '100px',
    //     showPlus: false,
    //     withColor: false,
    // },
]

export const BITCOIN_AND_OTHERS_METRIC_GROUPS = [
    {
        key: 'price',
        label: 'Price',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.price,
        withPercentage: true,
        width: COLUMN_WIDTH,
        showPlus: false,
        withColor: true,
    },
    {
        key: 'marketcap',
        label: 'Market Cap',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.marketcap,
        withPercentage: false,
        width: COLUMN_WIDTH,
        showPlus: false,
        withColor: false,
    },
    {
        key: 'daily_trading_volume',
        label: 'Trading Volume 24h',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.dailyVolume,
        withPercentage: true,
        width: COLUMN_WIDTH,
        showPlus: false,
        withColor: true,
    },
    // {
    //     key: 'reward_rate',
    //     label: 'Reward Rate',
    //     postfix: '%',
    //     tooltipTextObj: TOOLTIPS.rewardRate,
    //     withPercentage: true,
    //     width: '100px',
    //     showPlus: false,
    //     withColor: true,
    // },
]

export const STABLECOINS_SORT_BY_CHOICES = STABLECOIN_ASSET_METRIC_GROUPS.map(
    group => {
        return {
            key: group.key,
            name: group.label,
            withPercentage:
                group.key !== 'daily_trading_volume' && group.withPercentage,
        }
    }
)

export const BITCOIN_AND_OTHERS_SORT_BY_CHOICES =
    BITCOIN_AND_OTHERS_METRIC_GROUPS.map(group => {
        return {
            key: group.key,
            name: group.label,
            withPercentage:
                group.key !== 'daily_trading_volume' && group.withPercentage,
        }
    })

export const DEFAULT_STABLECOINS_SORT_BY = STABLECOINS_SORT_BY_CHOICES.find(
    choice => choice.key === 'marketcap'
)
